<template>
    <div>
        <cTop></cTop>
        <div class="container">
            <div class="nav-wrap">
                <div class="wrap" @click="() => {$router.push('/')}"><img class="logo" tabindex="0"
                                                                          src="../../assets/img/logo.png"><span
                        class="head-title" tabindex="0">供应链云-分销商平台</span></div>
            </div>
            <div class="container-content">
                <ul _ngcontent-ain-c394="" class="status-bar">
                    <li _ngcontent-ain-c394="" class="active">1、资料申请</li>
                    <li _ngcontent-ain-c394="">2、资质审核</li>
                    <li _ngcontent-ain-c394="">3、完成</li>
                </ul>
            </div>
            <a-spin :spinning="spinning">
                <div class="form-wrap">
                    <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" :form="form" @submit="handleSubmit">
                        <a-form-item label="营业执照" required>
                            <div slot="extra">（支持JPG、GIF、PNG、JPEG、BMP格式，文件小于2M）</div>
                            <div class="form-control-upload">
                                <div class="title">请上传营业执照原件的高清照片，若上传复印件，则需加盖公司印章；上传后系统会自动识别并填写</div>
                                <div class="upload-wrap">
                                    <div class="upload-left">
                                        <a-upload
                                                style="width: 143px; height:143px;"
                                                name="file"
                                                list-type="picture-card"
                                                class="avatar-uploader"
                                                :show-upload-list="false"
                                                :action="uploadUrl"
                                                @change="changeAvatar"
                                        >
                                            <img v-if="company.enterpriseQualificationCertificate"
                                                 style="width: 100%; height:100%;"
                                                 :src="company.enterpriseQualificationCertificate" alt="avatar"/>
                                            <div v-else>
                                                <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
                                                <div class="ant-upload-text">
                                                    点击上传
                                                </div>
                                            </div>
                                        </a-upload>
                                    </div>
                                    <div class="upload-right">
                                    </div>
                                </div>
                            </div>
                        </a-form-item>
                        <a-form-item label="公司名称" :wrapper-col="{ span: 8}">
                            <a-input
                                    size="large"
                                    placeholder="请输入公司名称(营业执照上的名称)"
                                    v-decorator="[
                                    'enterpriseName',
                                    { rules: [
                                        {
                                            required: true,
                                            message: '请输入公司名称'
                                        }
                                    ]}
                                ]"
                            ></a-input>
                        </a-form-item>
                        <a-form-item label="公司简称" :wrapper-col="{ span: 8}">
                            <a-input
                                    size="large"
                                    placeholder="请输入公司简称，最多十个字"
                                    v-decorator="[
                                    'enterpriseNameAbbreviation',
                                    { rules: [
                                        {
                                            required: true,
                                            message: '请输入公司简称，最多十个字'
                                        }
                                    ]}
                                ]"
                            ></a-input>
                        </a-form-item>
                        <a-form-item label="公司代码" :wrapper-col="{ span: 8}">
                            <a-input
                                    size="large"
                                    placeholder="请输入公司代码(统一社会信用代码)"
                                    v-decorator="[
                                    'unifiedSocialCreditCode',
                                    { rules: [
                                        {
                                            required: true,
                                            message: '请输入18位公司代码'
                                        }
                                    ]}
                                ]"
                            ></a-input>
                        </a-form-item>
                        <a-form-item label="公司法人" :wrapper-col="{ span: 8}">
                            <a-input
                                    size="large"
                                    placeholder="请输入法人姓名"
                                    v-decorator="[
                                    'legalPerson',
                                    { rules: [
                                        {
                                            required: true,
                                            message: '请输入法人姓名'
                                        }
                                    ]}
                                ]"
                            ></a-input>
                        </a-form-item>
                        <a-form-item label="公司所在地" :wrapper-col="{ span: 8}" required>
                            <a-row :gutter="8">
                                <a-col :span="8">
                                    <a-select size="large" placeholder="请选择" @change="changeProvince"
                                              v-decorator="[
                                                'provinceCode',
                                                {
                                                    initialValue:provinceCode,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ]}
                                            ]"
                                    >
                                        <a-select-option v-for="item in provinceList" :key="item.regionCode"
                                                         :value="item.regionCode">
                                            {{item.name}}
                                        </a-select-option>
                                    </a-select>
                                </a-col>
                                <a-col :span="8">
                                    <a-select v-if="cityList.length > 0" size="large" placeholder="请选择"
                                              @change="changeCity"
                                              v-decorator="[
                                                'cityCode',
                                                {
                                                    initialValue:cityCode,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ]}
                                            ]"
                                    >
                                        <a-select-option v-for="item in cityList" :key="item.regionCode"
                                                         :value="item.regionCode">
                                            {{item.name}}
                                        </a-select-option>
                                    </a-select>
                                </a-col>
                                <a-col :span="8">
                                    <a-select v-if="areaList.length > 0" size="large" placeholder="请选择"
                                              @change="changeArea"
                                              v-decorator="[
                                                'areaCode',
                                                {
                                                    initialValue:areaCode,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ]}
                                            ]"
                                    >
                                        <a-select-option v-for="item in areaList" :key="item.regionCode"
                                                         :value="item.regionCode">
                                            {{item.name}}
                                        </a-select-option>
                                    </a-select>
                                </a-col>
                            </a-row>
                        </a-form-item>
                        <a-form-item label="公司详细地址" :wrapper-col="{ span: 8}">
                            <a-textarea
                                    size="large"
                                    placeholder="请输入公司详细地址"
                                    v-decorator="[
                                    'enterpriseAddress',
                                    { rules: [
                                        {
                                            required: true,
                                            message: '请输入公司详细地址'
                                        }
                                    ]}
                                ]"
                            ></a-textarea>
                        </a-form-item>
                        <a-form-item label="营业期限开始时间" :wrapper-col="{ span: 8}">
                            <a-date-picker
                                    format="YYYY-MM-DD"
                                    value-format="YYYY-MM-DD"
                                    v-decorator="['operatingStartTime',  {
        rules: [{  required: true, message: '请选择日期' }],
      }]"
                            />
                        </a-form-item>
                        <a-form-item label="营业期限截止时间" :wrapper-col="{ span: 8}" required>
                            <a-date-picker
                                    format="YYYY-MM-DD"
                                    value-format="YYYY-MM-DD"
                                    v-decorator="['operatingEndTime',  {
        rules: [{  required: needOperatingEndTime, message: '请选择日期' }],
      }]"
                            />
                            <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">
        -
      </span>
                            <a-checkbox @change="onChange"

                                        v-decorator="['operatingLongTime', {rules: [{ required: true, type: 'boolean' }]}]"
                            >
                                长期
                            </a-checkbox>

                        </a-form-item>
                        <a-form-item label="联系人" :wrapper-col="{ span: 8}">
                            <a-input
                                    size="large"
                                    placeholder="请输入您的姓名"
                                    v-decorator="[
                                    'contactsName',
                                    { rules: [
                                        {
                                            required: true,
                                            message: '请输入联系人'
                                        }
                                    ]}
                                ]"
                            ></a-input>
                        </a-form-item>
                        <a-form-item label="联系人手机号" :wrapper-col="{ span: 8}">
                            <div class="contactsPhone">
                                <span>{{userInfo.phone}}</span>
                                <strong>默认当前登录账号绑定的手机号</strong>
                            </div>
                        </a-form-item>
                        <a-form-item label="公司电话" :wrapper-col="{ span: 8}">
                            <a-input
                                    size="large"
                                    placeholder="请输入公司电话"
                                    v-decorator="[
                                    'enterpriseTelephone',{

                                        initialValue:'',
                                    }
                                ]"
                            ></a-input>
                        </a-form-item>
                        <a-form-item label="公司邮箱" :wrapper-col="{ span: 8}">
                            <a-input
                                    size="large"
                                    placeholder="请输入公司邮箱"
                                    v-decorator="[
                                    'enterpriseEmail',
                                    {
                                        initialValue:'',
                                        rules: [
                                        {
                                            type:'email',
                                            message: '请输入公司邮箱'
                                        }
                                    ]}
                                ]"
                            ></a-input>
                        </a-form-item>
                        <a-form-item label=" " :colon="false" :wrapper-col="{ span: 8}">
                            <a-button type="primary" html-type="submit" block size="large" style="margin: auto;">提交
                            </a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </a-spin>
        </div>

    </div>
</template>

<script>
    import cTop from "@/component/top";
    import service from "../../../utils/request";

    export default {
        name: "attestation-add",
        components: {
            cTop,
        },
        data() {
            return {
                uploadUrl: '',
                spinning: false,
                uploadLoading: false,
                company: {
                    enterpriseQualificationCertificate: ''
                },
                userInfo: {},
                needOperatingEndTime: true,
                provinceList: [],
                cityList: [],
                areaList: [],
                provinceCode: undefined,
                cityCode: undefined,
                areaCode: undefined
            }
        },
        created() {
            this.uploadUrl = this.$apiBaseURL + '/api/index/uploadImg'
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
            this.getRegionByParent(0, 1);
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'register'});
        },
        methods: {
            changeProvince(e) {
                this.cityCode = undefined;
                this.cityList = [];
                this.areaList = [];
                this.getRegionByParent(e, 2);
            },
            changeCity(e) {
                this.areaCode = undefined;
                this.areaList = [];
                this.getRegionByParent(e, 3);
            },
            changeArea(e) {
                console.log(e)
            },
            getRegionByParent(parentCode, level) {
                service.post(service.uri.company.getRegionByParent, {parentCode: parentCode}).then(res => {
                    if (res.code == 200) {
                        if (level == 1) {
                            this.provinceList = res.data.list;
                        } else if (level == 2) {
                            this.cityList = res.data.list;
                        } else if (level == 3) {
                            this.areaList = res.data.list;
                        }
                    }
                });
            },
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFieldsAndScroll((err, values) => {
                    console.log(values)
                    if (!err) {
                        console.log('Received values of form: ', values);
                        let data = Object.assign(this.company, values);
                        service.post(service.uri.company.add, data).then(() => {
                            this.$router.push('/atc/attestation');
                        });
                    }
                });
            },
            changeAvatar(e) {
                console.log('changeAvatar:', e)
                if (e.file.status === 'done') {
                    // Get this url from response in real world.
                    let res = e.file.response;
                    console.log(res)
                    if (res.code == 1) {
                        this.company.enterpriseQualificationCertificate = res.data.url;
                        console.log('url:', this.company.enterpriseQualificationCertificate);
                    }
                }
            },
            onChange(e) {
                console.log('change:', e.target.checked)
                this.needOperatingEndTime = !e.target.checked
            }
        }
    }
</script>

<style lang="less" scoped>
    .form-control-upload {
        margin-left: 12px;

        .title {
            font-weight: 400;
            color: #999;
            font-size: 14px;
        }

        .upload-wrap {
            display: flex;

            .upload-right {
                width: 198px;
                height: 143px;
                /*margin-left: -270px;*/
                background: url('../../assets/img/eg.enterpriseQualificationCertificate.png') no-repeat 50%/100%;
            }
        }
    }

    .contactsPhone {
        span {
            color: #333;
        }

        strong {
            font-weight: 400;
            margin-left: 23px;
            color: #999;
        }
    }

    .form-wrap {
        width: 1188px;
        padding-top: 52px;
        margin: 0 auto;
    }

    .wrap {
        max-width: 1200px;
        margin: 0 auto;
        background-color: #fff;
    }

    .logo {
        width: 240px;
        height: 60px;
        margin-left: 2rem;
    }

    .container {
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
        width: 1200px;
        margin: auto;

        .nav-wrap {
            width: 100%;
            background-color: #fff;

            .wrap {
                padding: 14px 0 12px;

                .head-title {
                    margin-left: 1rem;
                    color: #333;
                    font-weight: 700;
                    font-size: 20px;
                    vertical-align: middle;
                }
            }
        }

        .container-content {
            flex: 1;
        }
    }

    .status-bar {
        display: flex;
        justify-content: space-between;
        width: 1188px;
        margin: 47px auto 0;

        li {
            height: 38px;
            line-height: 38px;
            text-align: center;
            width: 386px;
            background: #e3e3e3;
            color: #fff;
            cursor: pointer;
        }

        li.active {
            background: #de0000;
        }
    }
</style>