var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cTop'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"nav-wrap"},[_c('div',{staticClass:"wrap",on:{"click":function () {_vm.$router.push('/')}}},[_c('img',{staticClass:"logo",attrs:{"tabindex":"0","src":require("../../assets/img/logo.png")}}),_c('span',{staticClass:"head-title",attrs:{"tabindex":"0"}},[_vm._v("供应链云-分销商平台")])])]),_vm._m(0),_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"form-wrap"},[_c('a-form',{attrs:{"label-col":{ span: 8 },"wrapper-col":{ span: 14 },"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"营业执照","required":""}},[_c('div',{attrs:{"slot":"extra"},slot:"extra"},[_vm._v("（支持JPG、GIF、PNG、JPEG、BMP格式，文件小于2M）")]),_c('div',{staticClass:"form-control-upload"},[_c('div',{staticClass:"title"},[_vm._v("请上传营业执照原件的高清照片，若上传复印件，则需加盖公司印章；上传后系统会自动识别并填写")]),_c('div',{staticClass:"upload-wrap"},[_c('div',{staticClass:"upload-left"},[_c('a-upload',{staticClass:"avatar-uploader",staticStyle:{"width":"143px","height":"143px"},attrs:{"name":"file","list-type":"picture-card","show-upload-list":false,"action":_vm.uploadUrl},on:{"change":_vm.changeAvatar}},[(_vm.company.enterpriseQualificationCertificate)?_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.company.enterpriseQualificationCertificate,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.uploadLoading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 点击上传 ")])],1)])],1),_c('div',{staticClass:"upload-right"})])])]),_c('a-form-item',{attrs:{"label":"公司名称","wrapper-col":{ span: 8}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'enterpriseName',
                                { rules: [
                                    {
                                        required: true,
                                        message: '请输入公司名称'
                                    }
                                ]}
                            ]),expression:"[\n                                'enterpriseName',\n                                { rules: [\n                                    {\n                                        required: true,\n                                        message: '请输入公司名称'\n                                    }\n                                ]}\n                            ]"}],attrs:{"size":"large","placeholder":"请输入公司名称(营业执照上的名称)"}})],1),_c('a-form-item',{attrs:{"label":"公司简称","wrapper-col":{ span: 8}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'enterpriseNameAbbreviation',
                                { rules: [
                                    {
                                        required: true,
                                        message: '请输入公司简称，最多十个字'
                                    }
                                ]}
                            ]),expression:"[\n                                'enterpriseNameAbbreviation',\n                                { rules: [\n                                    {\n                                        required: true,\n                                        message: '请输入公司简称，最多十个字'\n                                    }\n                                ]}\n                            ]"}],attrs:{"size":"large","placeholder":"请输入公司简称，最多十个字"}})],1),_c('a-form-item',{attrs:{"label":"公司代码","wrapper-col":{ span: 8}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'unifiedSocialCreditCode',
                                { rules: [
                                    {
                                        required: true,
                                        message: '请输入18位公司代码'
                                    }
                                ]}
                            ]),expression:"[\n                                'unifiedSocialCreditCode',\n                                { rules: [\n                                    {\n                                        required: true,\n                                        message: '请输入18位公司代码'\n                                    }\n                                ]}\n                            ]"}],attrs:{"size":"large","placeholder":"请输入公司代码(统一社会信用代码)"}})],1),_c('a-form-item',{attrs:{"label":"公司法人","wrapper-col":{ span: 8}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'legalPerson',
                                { rules: [
                                    {
                                        required: true,
                                        message: '请输入法人姓名'
                                    }
                                ]}
                            ]),expression:"[\n                                'legalPerson',\n                                { rules: [\n                                    {\n                                        required: true,\n                                        message: '请输入法人姓名'\n                                    }\n                                ]}\n                            ]"}],attrs:{"size":"large","placeholder":"请输入法人姓名"}})],1),_c('a-form-item',{attrs:{"label":"公司所在地","wrapper-col":{ span: 8},"required":""}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":8}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                            'provinceCode',
                                            {
                                                initialValue:_vm.provinceCode,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择'
                                                }
                                            ]}
                                        ]),expression:"[\n                                            'provinceCode',\n                                            {\n                                                initialValue:provinceCode,\n                                            rules: [\n                                                {\n                                                    required: true,\n                                                    message: '请选择'\n                                                }\n                                            ]}\n                                        ]"}],attrs:{"size":"large","placeholder":"请选择"},on:{"change":_vm.changeProvince}},_vm._l((_vm.provinceList),function(item){return _c('a-select-option',{key:item.regionCode,attrs:{"value":item.regionCode}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-col',{attrs:{"span":8}},[(_vm.cityList.length > 0)?_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                            'cityCode',
                                            {
                                                initialValue:_vm.cityCode,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择'
                                                }
                                            ]}
                                        ]),expression:"[\n                                            'cityCode',\n                                            {\n                                                initialValue:cityCode,\n                                            rules: [\n                                                {\n                                                    required: true,\n                                                    message: '请选择'\n                                                }\n                                            ]}\n                                        ]"}],attrs:{"size":"large","placeholder":"请选择"},on:{"change":_vm.changeCity}},_vm._l((_vm.cityList),function(item){return _c('a-select-option',{key:item.regionCode,attrs:{"value":item.regionCode}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_vm._e()],1),_c('a-col',{attrs:{"span":8}},[(_vm.areaList.length > 0)?_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                            'areaCode',
                                            {
                                                initialValue:_vm.areaCode,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择'
                                                }
                                            ]}
                                        ]),expression:"[\n                                            'areaCode',\n                                            {\n                                                initialValue:areaCode,\n                                            rules: [\n                                                {\n                                                    required: true,\n                                                    message: '请选择'\n                                                }\n                                            ]}\n                                        ]"}],attrs:{"size":"large","placeholder":"请选择"},on:{"change":_vm.changeArea}},_vm._l((_vm.areaList),function(item){return _c('a-select-option',{key:item.regionCode,attrs:{"value":item.regionCode}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_vm._e()],1)],1)],1),_c('a-form-item',{attrs:{"label":"公司详细地址","wrapper-col":{ span: 8}}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'enterpriseAddress',
                                { rules: [
                                    {
                                        required: true,
                                        message: '请输入公司详细地址'
                                    }
                                ]}
                            ]),expression:"[\n                                'enterpriseAddress',\n                                { rules: [\n                                    {\n                                        required: true,\n                                        message: '请输入公司详细地址'\n                                    }\n                                ]}\n                            ]"}],attrs:{"size":"large","placeholder":"请输入公司详细地址"}})],1),_c('a-form-item',{attrs:{"label":"营业期限开始时间","wrapper-col":{ span: 8}}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['operatingStartTime',  {
    rules: [{  required: true, message: '请选择日期' }],
  }]),expression:"['operatingStartTime',  {\n    rules: [{  required: true, message: '请选择日期' }],\n  }]"}],attrs:{"format":"YYYY-MM-DD","value-format":"YYYY-MM-DD"}})],1),_c('a-form-item',{attrs:{"label":"营业期限截止时间","wrapper-col":{ span: 8},"required":""}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['operatingEndTime',  {
    rules: [{  required: _vm.needOperatingEndTime, message: '请选择日期' }],
  }]),expression:"['operatingEndTime',  {\n    rules: [{  required: needOperatingEndTime, message: '请选择日期' }],\n  }]"}],attrs:{"format":"YYYY-MM-DD","value-format":"YYYY-MM-DD"}}),_c('span',{style:({ display: 'inline-block', width: '24px', textAlign: 'center' })},[_vm._v(" - ")]),_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['operatingLongTime', {rules: [{ required: true, type: 'boolean' }]}]),expression:"['operatingLongTime', {rules: [{ required: true, type: 'boolean' }]}]"}],on:{"change":_vm.onChange}},[_vm._v(" 长期 ")])],1),_c('a-form-item',{attrs:{"label":"联系人","wrapper-col":{ span: 8}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'contactsName',
                                { rules: [
                                    {
                                        required: true,
                                        message: '请输入联系人'
                                    }
                                ]}
                            ]),expression:"[\n                                'contactsName',\n                                { rules: [\n                                    {\n                                        required: true,\n                                        message: '请输入联系人'\n                                    }\n                                ]}\n                            ]"}],attrs:{"size":"large","placeholder":"请输入您的姓名"}})],1),_c('a-form-item',{attrs:{"label":"联系人手机号","wrapper-col":{ span: 8}}},[_c('div',{staticClass:"contactsPhone"},[_c('span',[_vm._v(_vm._s(_vm.userInfo.phone))]),_c('strong',[_vm._v("默认当前登录账号绑定的手机号")])])]),_c('a-form-item',{attrs:{"label":"公司电话","wrapper-col":{ span: 8}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'enterpriseTelephone',{

                                    initialValue:'',
                                }
                            ]),expression:"[\n                                'enterpriseTelephone',{\n\n                                    initialValue:'',\n                                }\n                            ]"}],attrs:{"size":"large","placeholder":"请输入公司电话"}})],1),_c('a-form-item',{attrs:{"label":"公司邮箱","wrapper-col":{ span: 8}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'enterpriseEmail',
                                {
                                    initialValue:'',
                                    rules: [
                                    {
                                        type:'email',
                                        message: '请输入公司邮箱'
                                    }
                                ]}
                            ]),expression:"[\n                                'enterpriseEmail',\n                                {\n                                    initialValue:'',\n                                    rules: [\n                                    {\n                                        type:'email',\n                                        message: '请输入公司邮箱'\n                                    }\n                                ]}\n                            ]"}],attrs:{"size":"large","placeholder":"请输入公司邮箱"}})],1),_c('a-form-item',{attrs:{"label":" ","colon":false,"wrapper-col":{ span: 8}}},[_c('a-button',{staticStyle:{"margin":"auto"},attrs:{"type":"primary","html-type":"submit","block":"","size":"large"}},[_vm._v("提交 ")])],1)],1)],1)])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-content"},[_c('ul',{staticClass:"status-bar",attrs:{"_ngcontent-ain-c394":""}},[_c('li',{staticClass:"active",attrs:{"_ngcontent-ain-c394":""}},[_vm._v("1、资料申请")]),_c('li',{attrs:{"_ngcontent-ain-c394":""}},[_vm._v("2、资质审核")]),_c('li',{attrs:{"_ngcontent-ain-c394":""}},[_vm._v("3、完成")])])])}]

export { render, staticRenderFns }